








import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'PendingDeposit' })
export default class Login extends Vue {}
